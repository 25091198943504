<template>
    <v-dialog v-model="value" persistent max-width="500px">
        <v-card>
            <v-card-title class="headline">{{ title }}</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="confirmationPassword"
                    type="password"
                    placeholder="Введите пароль"
                    color="purple darken-2"
                />
            </v-card-text>
            <v-alert
                v-if="!_.isNil(confirmationError)"
                dense
                type="error"
                class="ma-2"
            >
                    {{ confirmationError }}
            </v-alert>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    color="purple darken-2"
                    :loading="loading"
                    @click="$emit('change', false)"
                >Отмена</v-btn>
                <v-btn
                    :dark="!!confirmationPassword"
                    color="purple darken-2"
                    :disabled="!confirmationPassword"
                    :loading="loading"
                    outlined
                    @click="actionHandler"
                >OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: { type: Boolean, default: false },
        title: { type: String, default: 'Для подтверждения действия введите пароль от текущей учетной записи' },
        action: { type: Function, required: true }
    },
    data () {
        return {
            loading: false,
            confirmationPassword: '',
            confirmationError: null
        }
    },
    methods: {
        async actionHandler () {
            try {
                this.confirmationError = null

                this.loading = true
                
                const loginHandler = { login: this.$store.state.user.profile.login, password: this.confirmationPassword }
                const { success: s1, data: userData, error: e1 } = await this.$store.dispatch('user/login', loginHandler)
                        
                if (!s1)
                    throw new Error(e1)
                
                this.$store.dispatch('user/setSession', userData)

                await this.action()

                this.$emit('change', false)
            } catch (e) {
                console.error(e)
                this.confirmationError = e?.message
            } finally {
                this.loading = false
            }
        }
    }
}

</script>