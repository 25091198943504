<template>
    <v-dialog v-model="value" persistent max-width="600px">
        <v-card>
            <v-toolbar dark color="purple darken-2">
                <v-toolbar-title>Фильтр</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <slot :model="model" />
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red" dark @click.prevent.stop="reset">
                    Сбросить
                </v-btn>
                
                <v-spacer></v-spacer>

                <v-btn outlined color="warning" @click.prevent.stop="close">
                    Закрыть
                </v-btn>
                <v-btn color="purple darken-2" dark @click.prevent.stop="apply">
                    Применить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'GridView',
    props: {
        value: Boolean,
        storeModule: String,
        extraFilters: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            loading: true,
            model: {}
        }
    },
    watch: {
        '$route.query': {
            handler () {
                if (!Object.keys(this.extraFilters).length) { return false; }
                this.fetch();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            const filter = _.get(this, `$store.state.${this.storeModule}.filter`, {});
            _.assign(
                this.model,
                _.cloneDeep(filter),
                this.extraFilters
            );
        },
        async apply() {
            this.close();
            this.loading = true;
            const model = _.cloneDeep(this.model)
            
            for (const key in model) {
                // Skip values with empty strings
                if (model[key] === "") { model[key] = null }
                
                if (model[key] && this.$store.state.user.filterRules[key])
                    model[key] = this.$store.state.user.filterRules[key](model[key])
            }
            
            await this.$store.dispatch(`${this.storeModule}/filter`, model);
            this.$emit('filter', 1);

            this.loading = false;
        },
        async reset() {
            this.loading = true;

            await this.$store.dispatch(`${this.storeModule}/reset`);
            this.fetch();
            this.apply();
        },
        close() {
            return this.$emit('close', 1);
        }
    },
    created() {
        this.fetch();
    }
}
</script>
<style lang="scss">
</style>