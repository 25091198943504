<template>
    <div class="grid-view">
        <v-row align="start" justify="start" dark>
            <v-col cols="12" class="d-flex flex-start align-center">
                <div class="text-h6" v-html="title"></div>

                <template v-if="filter">

                    <v-divider vertical class="mx-2" />

                    <v-btn outlined small color="purple darken-2" class="ml-auto white--text" @click.prevent.stop="toggleFilter(true)">
                        <v-icon left dark>
                            mdi-filter-variant
                        </v-icon>
                        <span>Фильтр</span>
                    </v-btn>
                </template>

                <v-spacer />

                <slot name="title.right" />

                <v-btn v-if="create" :to="urlCreate" color="purple darken-2" class="ml-auto white--text">
                    <v-icon left dark>
                        mdi-plus
                    </v-icon>
                    <span v-html="createTitle"></span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row align="start" justify="start" dark>
            <v-col cols="12">
                <!-- Data table -->
                <v-data-table
                    :loading="loading"
                    dense
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="itemsPerPage"
                    :footer-props="{
                        'disable-items-per-page': true,
                        'items-per-page-options': [],
                        'show-current-page': true,
                        'show-first-last-page': true
                    }"
                    calculate-widths
                    :headers="headers"
                    :items="items"
                    item-key="id"
                    class="elevation-1"
                >
                    <template
                        v-for="header in headers"
                        #[`item.${header.value}`]="{ item }"
                    >
                        <slot :name="`item.${header.value}`" :item="item">{{ Array.isArray(item[header.value]) ? item[header.value].join(', ') : item[header.value]  }}</slot>
                    </template>

                    <template #[`item.edit`]="{ item }">
                        <v-icon v-if="checkEditPermission(item)" small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <template #[`item.remove`]="{ item }">
                        <v-icon v-if="checkDeletePermission(item)" small @click="removeHandler(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>

                <confirm-action-with-pass-dialog
                    v-model="popup.remove"
                    title="Для подтверждения удаления введите пароль от текущей учетной записи"
                    :action="remove"
                />
            </v-col>
        </v-row>

        <filter-view 
            v-model="popup.filter" 
            v-slot="filterScope"
            :store-module="storeModule" 
            :extra-filters="extraFilters"
            @close="toggleFilter(false)"
            @filter="applyFilter"
        >
            <slot name="filter" :filter="filterScope.model" />
        </filter-view>
    </div>
</template>
<script>
import FilterView from './FilterView.vue'
import ConfirmActionWithPassDialog from '@/components/template/ConfirmActionWithPassDialog.vue'

export default {
    name: 'GridView',
    components: { FilterView, ConfirmActionWithPassDialog },
    props: {
        title: String,
        filter: {
            type: Boolean,
            default: false
        },
        create: {
            type: Boolean,
            default: false
        },
        createTitle: {
            type: String,
            default: 'Создать'
        },
        urlCreate: String,
        urlUpdate: String,
        storeModule: String,
        actionFetch: {
            type: String,
            default: 'list'
        },
        actionDelete: {
            type: String,
            default: 'delete'
        },
        modelFields: Array, // Поля модели, которые необходимо запросить по api. Не обязательно.
        headers: Array, // Заголовки таблицы. Если не указано modelFields, то поля для модели будут сформированы из этих значений
        format: {
            type: Function,
            default: (items) => {
                return items;
            }
        },
        checkEditPermission: {
            type: Function,
            default: function() {
                return true;
            }
        },
        checkDeletePermission: {
            type: Function,
            default: function() {
                return true;
            }
        },
        extraFilters: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            loading: true,
            popup: {
                remove: false,
                filter: false
            },
            selected: [],
            selectedOne: null,
            total: 0,
            options: {
                ..._.cloneDeep(_.get(this, `$store.state.${this.storeModule}.grid.index.options`, {})),
                filter: _.assign(
                        this.getFilter(),
                        this.extraFilters
                    )
            },
            items: [],
            fetchKey: null
        }
    },
    watch: {
        '$route.query': {
            async handler () {
                await this.$store.dispatch(`${this.storeModule}/reset`)

                if (!Object.keys(this.extraFilters).length) { return false; }
                
                this.options.filter = _.assign(
                    _.cloneDeep(_.get(this, `$store.state.${this.storeModule}.filter`)),
                    this.extraFilters
                );
                this.options.page = 1;
            },
            deep: true
        },
        options: {
            handler () {
                this.$store.dispatch(`${this.storeModule}/updateGridOptions`, {
                    index: _.omit(this.options, ['filter'])
                });
                this.fetch();
            },
            deep: true
        }
    },
    computed: {
        itemsPerPage () {
            return this.options?.itemsPerPage || 10
        }
    },
    methods: {
        async fetch() {
            if (this.fetchKey === JSON.stringify(this.options) && this.loading) { return false; }
            
            this.loading = true;
            this.fetchKey = JSON.stringify(this.options);
            const options = this.cleanFetchOptions(this.options)
            const fields = this.modelFields?.length ?
                                this.modelFields.join(',') :
                                this.headers.filter(o => !['remove', 'edit'].includes(o.value)).map(o => o.value).join(',')
            options.fields = fields

            for (const key in options.filter) {
                    const element = options.filter[key]
                    if (Array.isArray(element))
                        options.filter[key] = { in: element } 
            }

            const {success, data, error} = await this.$store.dispatch(`${this.storeModule}/${this.actionFetch}`, options);
            if (!success) return console.error(error);
            
            this.total = _.get(data, 'total', 0)
            this.items = this.format(_.get(data, 'items', []))
            this.loading = false
            this.fetchKey = null
            this.$emit('fetched', { items: this.items, total: this.items.length })
        },
        cleanFetchOptions (data) {
            const options = _.cloneDeep(data);
            // Remove null values
            for (const key in options.filter) {
                if (options.filter[key] === null) {
                    delete options.filter[key]
                }
            }
            // Pagination
            options.pagination = `${options.page || 1}:${options?.itemsPerPage || this.itemsPerPage}`;
            // Sorting
            options.sort = []
            options.sortBy.forEach((key, index) => {
                options.sort.push(options.sortDesc[index] ? key : `-${key}`)
            })
            options.sort = options.sort.join(',')
            // Removing excess fields
            delete options.page;
            delete options.options;
            delete options.sortBy;
            delete options.sortDesc;
            delete options.itemsPerPage;
            return options;
        },
        edit(item) {
            const urlUpdate = _.replace(this.urlUpdate, ':id', item.id);
            return this.$router.push(urlUpdate).catch(() => {});
        },
        removeHandler (item) {
            if(_.isNil(item))
            {
                this.selectedOne = null
                this.popup.remove = false
                return
            }
            // Confirm remove popup
            if(!this.popup.remove)
            {
                this.selectedOne = item
                this.popup.remove = true
                return
            }
        },
        async remove () {
            const { success, error } = await this.$store.dispatch(`${this.storeModule}/${this.actionDelete}`, { id: _.get(this.selectedOne, 'id', 0) })
            if (!success)
                throw new Error(error)
            await this.fetch()
        },
        getFilter() {
            return _.omitBy(_.cloneDeep(_.get(this, `$store.state.${this.storeModule}.filter`, {})), _.isNil);
        },
        toggleFilter(value) {
            this.popup.filter = value === true;
        },
        applyFilter() {
            this.options.filter = this.getFilter();
            this.options.page = 1;
        }
    }
}
</script>
<style lang="scss">
    .grid-view{
        table {
            thead {
                th {
                    white-space: nowrap!important;
                }
            }
        }
    }
</style>